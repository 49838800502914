import React from "react";
import { Box, Avatar, Typography } from "@mui/joy";

const Header = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        p: 2,
        bgcolor: "background.surface",
        borderRadius: "sm",
      }}
    >
      <Box
        sx={{
          pr: 1,
        }}
      >
        <img src="/assets/logo.png" width={24} height={24} alt="Product Logo" />
      </Box>
      <Typography level="h4" component="h1">
        fedbird
      </Typography>
    </Box>
  );
};

export default Header;
