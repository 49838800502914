import React from "react";
import { Container, CssVarsProvider } from "@mui/joy";
import Header from "./components/Header";
import UploadForm from "./components/UploadForm";

function App() {
  return (
    <CssVarsProvider>
      <Container maxWidth="md">
        <Header />
        <UploadForm />
      </Container>
    </CssVarsProvider>
  );
}

export default App;
