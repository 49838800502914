import React, { useRef, useState } from "react";
import axios from "axios";
import {
  Container,
  Box,
  FormControl,
  Button,
  Input,
  Typography,
} from "@mui/joy";

const UploadForm = () => {
  const fileInputRef = useRef(null);
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if a valid email has been provided
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Check if a .CSV file has been uploaded
    if (!file || file.type !== "text/csv") {
      alert("Please upload a .CSV file.");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // Request a pre-signed URL from the server
      const response = await axios.post(`${apiUrl}/api/upload-url`, {
        email: email,
        filename: file.name,
        filetype: file.type,
      });

      const { presignedUrl, uniqueFilename } = response.data;

      // Upload the file to S3 using the pre-signed URL
      await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      alert(`File uploaded successfully: ${uniqueFilename}`);
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response ? error.response.data : error.message
      );
      alert("An error occurred while uploading the file. Please try again.");
    }
  };

  return (
    <Container maxWidth="xs" sx={{ py: 4 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pt: 2,
          pb: 4,
          px: 5,
          bgcolor: "background.surface",
          borderRadius: "md",
          boxShadow: "md",
        }}
      >
        <div>
          <Typography level="h4" component="h1">
            <b>Get Started</b>
          </Typography>
          <Typography level="body-sm">
            Enter your email address and upload a CSV. You will receive an email
            with the results.
          </Typography>
        </div>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl>
            <Input
              placeholder="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ flexBasis: "75%" }}
            />
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleUploadClick}
            sx={{ flexBasis: "25%" }}
          >
            Upload
            <input
              type="file"
              accept=".csv"
              hidden
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Button>
        </Box>
        <Button variant="solid" type="submit">
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default UploadForm;
